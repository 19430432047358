.root {
  outline: none;
}

.contentWrapper {
  margin-bottom: 0;

  & ul {
    & li {
      padding: 0;
    }
  }

  & input[type='checkbox']+label {
    display: flex;
    cursor: pointer;
  }

  & label {
    &>span {
      &:first-child {
        display: none;
      }

      /* &:last-child {
      } */
    }
  }

  & input[type='checkbox'] {
    display: none;
  }

  & input[type='checkbox']+label {
    background-color: transparent;
    width: 100%;
    color: #ffffff;

    &>span {
      color: var(--colorGrey700);
      font-size: 12px;
    }
  }

  & input[type='checkbox']:focus+label {
    /* background-color: #05c90c; */

    &>span {
      color: #000000;
    }
  }

  & input[type='checkbox']:checked+label {
    background-color: #747ac8;
    border: 0.5px solid #cacaca;
    padding: 0 10px;

    &>span {
      color: #ffffff;
      font-size: 12px;
    }
  }
}

.buttonsWrapper {
  display: flex;
  padding: 0 30px 16px 30px;
}

.clearButton,
.cancelButton,
.submitButton {
  composes: textSmall from global;
  font-weight: var(--fontWeightMedium);

  /* Layout */
  padding: 0;

  /* Override button styles */
  outline: none;
  border: none;
  cursor: pointer;

  &:focus,
  &:hover {
    transition: width var(--transitionStyleButton);
  }
}

.clearButton {
  color: var(--colorGrey300);

  /* Layout */
  margin: 0 auto 0 0;

  &:focus,
  &:hover {
    color: var(--colorGrey700);
  }
}

.cancelButton {
  color: var(--colorGrey300);

  /* Layout */
  margin: 0;

  /* clearButton will add all available space between cancelButton,
   * but some hard coded margin is still needed
   */
  margin-left: 48px;

  &:focus,
  &:hover {
    color: var(--colorGrey700);
  }
}

.submitButton {
  color: var(--marketplaceColor);

  /* Layout */
  margin: 0 0 0 19px;

  &:focus,
  &:hover {
    color: var(--marketplaceColorDark);
  }
}