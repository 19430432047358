@import '../../../styles/customMediaQueries.css';

.root {
  position: relative;
  display: inline-block;
}

.fieldGroup {
  margin: 0;
  padding: 0;
  border: none;
}

.fieldGroupPlain {
  margin: 0;
  padding-left: 12px;
  padding-bottom: 6px;
  border: none;

  @media (--viewportMedium) {
    padding: 0;
}
}

.list {
  margin: 0;
  height: 200px;
  position: absolute;
  background: #fff;
  z-index: 999;
  overflow-y: auto;
  border: 1px solid #f1f1f1;
  padding: 10px;
  width: 100%;
}

.item {
  padding: 4px 0 2px 0;

  /* Fix broken multi-column layout in Chrome */
  page-break-inside: avoid;

  @media (--viewportMedium) {
    padding: 4px 0;
  }
}
